import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ResourceCard = makeShortcode("ResourceCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "component-contributions-can-take-several-forms-most-are-either-component-enhancements-or-brand-new-assets"
    }}>{`Component contributions can take several forms. Most are either component enhancements or brand new assets.`}</h3>
    <h2 {...{
      "id": "first-steps-to-contributing"
    }}>{`First steps to contributing`}</h2>
    <p>{`To contribute a component to Carbon, start by `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon/issues/new?assignees=&labels=type%3A+enhancement+%F0%9F%92%A1&template=feature-request-or-enhancement.md&title="
      }}>{`opening a Github issue`}</a>{`. Include a detailed description in which you:`}</p>
    <ul>
      <li parentName="ul">{`Explain the rationale`}</li>
      <li parentName="ul">{`Detail the intended behavior`}</li>
      <li parentName="ul">{`Clarify whether it’s a variation of an existing component, or a new asset`}</li>
      <li parentName="ul">{`Include mockups of any fidelity (optional)`}</li>
      <li parentName="ul">{`Include any inspirations from other products (optional)`}</li>
    </ul>
    <p>{`This issue will be the staging ground for the contribution and an opportunity for the community to weigh in with any suggestions. We’ll also be able to tell you up-front whether the contribution is likely to become part of Carbon, and potentially save you time.`}</p>
    <p>{`We encourage you to surface works-in-progress. If you’re not able to complete all of the parts yourself, someone in the community may be able to pick up where you leave off.`}</p>
    <h2 {...{
      "id": "parts-of-a-component-contribution"
    }}>{`Parts of a component contribution`}</h2>
    <p>{`Here are all of the steps for a complete contribution.`}</p>
    <h4 {...{
      "id": "1-provide-a-rationale"
    }}>{`1. Provide a rationale`}</h4>
    <p>{`Explain how your component will add value to the system. Carbon serves the widest possible range of products, and contributions that increase the scope of the system are more likely to be accepted. Be sure to include any user experience and interaction descriptions.`}</p>
    <h4 {...{
      "id": "2-create-a-design-spec"
    }}>{`2. Create a design spec`}</h4>
    <p>{`Create sizing and styling annotations for all aspects of the component. This spec should provide a developer with everything they need to create the design in code. Check out our `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-website/wiki/Production-guidelines#spec-guidelines"
      }}>{`production guidelines`}</a>{` to get started.`}</p>
    <p>{`You should include color tokens and type tokens used.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "35.733695652173914%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of a design spec",
        "title": "Example of a design spec",
        "src": "/static/85f0796a9dfc8a6a842dbaaa1d4e1cbf/fb070/content-switcher-style-1.png",
        "srcSet": ["/static/85f0796a9dfc8a6a842dbaaa1d4e1cbf/d6747/content-switcher-style-1.png 288w", "/static/85f0796a9dfc8a6a842dbaaa1d4e1cbf/09548/content-switcher-style-1.png 576w", "/static/85f0796a9dfc8a6a842dbaaa1d4e1cbf/fb070/content-switcher-style-1.png 1152w", "/static/85f0796a9dfc8a6a842dbaaa1d4e1cbf/c3e47/content-switcher-style-1.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h4 {...{
      "id": "3-create-a-sketch-symbol"
    }}>{`3. Create a Sketch symbol`}</h4>
    <p>{`Any new components or changes to existing components will also live in the `}<a parentName="p" {...{
        "href": "/getting-started/designers#get-the-kit"
      }}>{`Carbon Sketch kit`}</a>{` and so we’ll need a Sketch symbol. Check out `}<a parentName="p" {...{
        "href": "https://www.sketch.com/docs/symbols/creating-symbols/"
      }}>{`Sketch’s guide`}</a>{` for creating a symbol.`}</p>
    <p>{`This symbol can be contributed with the asset or enhancement, but must be added to the kit by one of its `}<a parentName="p" {...{
        "href": "/contributions/overview/overview#maintainers"
      }}>{`maintainers`}</a>{`. To contribute a symbol, simply open an issue in the `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-design-kit/issues"
      }}>{`kit repo`}</a>{`.`}</p>
    <h4 {...{
      "id": "4-provide-usage-documentation"
    }}>{`4. Provide usage documentation`}</h4>
    <p>{`If the contribution adds additional behavior or expands a component’s features, you’ll need to document them with usage guidance, which will go in the usage tab of the component page. Color and type tokens will live in the style tab.`}</p>
    <p>{`See our `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-website/wiki/Production-guidelines#spec-guidelines"
      }}>{`production guidelines`}</a>{` and the `}<a parentName="p" {...{
        "href": "/contributions/documentation"
      }}>{`documentation guidelines`}</a>{` for help documenting your work. Reading through existing component documentation on the site will help also.`}</p>
    <h4 {...{
      "id": "5-provide-working-code"
    }}>{`5. Provide working code`}</h4>
    <p>{`The component or enhancement must be built in one of our supported frameworks (Vanilla, React, Vue, or Angular). See the contribution guidelines for the specific repo you intend to contribute to.`}</p>
    <Row className="tile--group" mdxType="Row">
      <Column offsetLg="4" colMd="4" colLg="4" noGutterSm mdxType="Column">
    <ResourceCard title="Contribute to Carbon Components React" href="https://github.com/carbon-design-system/carbon/blob/master/.github/CONTRIBUTING.md" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "98%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "github icon",
              "title": "github icon",
              "src": "/static/685e175fe4a49ccc584e92390bde04d2/7d71f/github-icon.png",
              "srcSet": ["/static/685e175fe4a49ccc584e92390bde04d2/7d71f/github-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column colLg="4" noGutterSm mdxType="Column">
  <ResourceCard title="Contribute to Carbon Components Angular" href="https://github.com/IBM/carbon-components-angular/blob/master/README.md#contributing" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "98%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "github icon",
              "title": "github icon",
              "src": "/static/685e175fe4a49ccc584e92390bde04d2/7d71f/github-icon.png",
              "srcSet": ["/static/685e175fe4a49ccc584e92390bde04d2/7d71f/github-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
  </ResourceCard>
  
      </Column>
      <Column offsetLg="4" colMd="4" colLg="4" noGutterSm mdxType="Column">
    <ResourceCard title="Contribute to Carbon Components Vue" href="https://github.com/carbon-design-system/carbon-components-vue/blob/master/.github/CONTRIBUTING.md" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "98%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "github icon",
              "title": "github icon",
              "src": "/static/685e175fe4a49ccc584e92390bde04d2/7d71f/github-icon.png",
              "srcSet": ["/static/685e175fe4a49ccc584e92390bde04d2/7d71f/github-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      